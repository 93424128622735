<!--Container-fluid starts....-->
<!-- <mat-stepper
  class="example-stepper"
  [orientation]="(stepperOrientation | async)!"
  [linear]="true"
  [selectedIndex]="selectedStep"
  #stepper>
  <mat-step label="Elige o Crea tu plantilla">
    <div class="row" style="text-align: center;">
       -->
<!-- <div class="col-md-4 col-sm-12" style="margin-bottom: 2rem;">
        <h3>Plantilla 1</h3>
        <a href="https://dev.learnify.com.mx/landing/curso-obs" target="_blank" class="image-link">
          <img src="../../../assets/images/landing1.png" alt="Landing" style="border-radius: 4px;">
          <span class="overlay-text">Ver</span>
        </a>
        <div>
          <input type="radio" name="option" value="1" (change)="goToNextStep($event.target.value)"> Seleccionar
        </div>
      </div> -->
<!-- <div class="col-md-5 " style="margin-bottom: 2rem;">
        <h3>Usar Plantilla</h3>
        <a href="https://www.lidr.co/dev2lead" target="_blank" class="image-link">
          <img src="../../../assets/images/landing2.png" alt="Landing" style="border-radius: 4px;">
          <span class="overlay-text">Ver</span>
        </a>
        <div>
          <input type="radio" name="option" value="2" (change)="goToNextStep($event.target.value)"> Seleccionar
        </div>
      </div>
      <div class="col-md-5  " style="margin-bottom: 2rem;">
        <h3>Crear Plantilla</h3>
        <a href="landing1/curso-obs" target="_blank" class="image-link">
          <img src="../../../assets/images/CrearLanding.png" alt="Landing" style="border-radius: 4px;">
          <span class="overlay-text">Crear Plantilla</span>
        </a>
        <div>
          <input type="radio" name="option" value="3" (change)="goToNextStep($event.target.value)"> Seleccionar
        </div>
      </div> -->
<!-- </div>
  </mat-step>
  <mat-step label="Contenido">
  
   
    <app-grapesjs *ngIf="crearPlantilla"></app-grapesjs>
   
   
  </mat-step>
</mat-stepper> -->
<div class="row">
  <div class="col-md-4">
    <div *ngIf="!crearPlantilla"><!--ngIf*="!crearPlantilla"-->
      <form [formGroup]="infoForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>Titulo Gancho</mat-label>
          <input matInput type="text" required class="w-100" formControlName="gancho" [(ngModel)]="gancho"
            placeholder="Titulo Gancho" [ngClass]="{'is-invalid': isFieldInvalid('gancho')}" required>
          <mat-error *ngIf="isFieldInvalid('gancho')">Este campo es requerido.</mat-error>
        </mat-form-field>

        <!-- <div class="mb-3">
          <label for="micronicho" class="form-label">Micronicho</label>
          <input type= "text" formControlName="micronicho" [(ngModel)]="micronicho" class="form-control placeholder-text" placeholder="Micronicho*" [ngClass]="{'is-invalid': isFieldInvalid('micronicho')}" required>
          <div *ngIf="isFieldInvalid('micronicho')" class="text-danger">
            Este campo es requerido.
          </div>
        </div> -->
        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>Propuesta de valor</mat-label>
          <input matInput type="text" formControlName="propuesta" [(ngModel)]="propuesta"
            placeholder="Propuesta de valor" [ngClass]="{'is-invalid': isFieldInvalid('propuesta')}" required>
          <mat-error *ngIf="isFieldInvalid('propuesta')">
            Este campo es requerido.
          </mat-error>
        </mat-form-field>


        <!-- <div class="mb-3">
          <label for="propuesta" class="form-label">Propuesta de valor</label>
          <input type= "text" formControlName="propuesta" [(ngModel)]="propuesta" class="form-control placeholder-text" placeholder=" Propuesta de valor*" [ngClass]="{'is-invalid': isFieldInvalid('propuesta')}" required>
          <div *ngIf="isFieldInvalid('propuesta')" class="text-danger">
            Este campo es requerido.
          </div>
        </div> -->
        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>Deseo</mat-label>
          <input matInput type="text" formControlName="GatilloM" [(ngModel)]="deseo" placeholder="Deseo"
            [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
          <mat-error *ngIf="isFieldInvalid('deseo')">
            Este campo es requerido.
          </mat-error>
        </mat-form-field>

        <!-- <div class="mb-3">
          <label for=" deseo" class="form-label">Gatillo Mentales</label>
          <input type= "text" formControlName="GatilloM" [(ngModel)]="GatilloM" class="form-control placeholder-text" placeholder=" Gatillo Mentales*" [ngClass]="{'is-invalid': isFieldInvalid('GatilloM')}" required>
          <div *ngIf="isFieldInvalid(' GatilloM')" class="text-danger">
            Este campo es requerido.
          </div>
        </div> -->

        <!-- <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>¿Qué obtendrás en nuestro curso?</mat-label>
          <input matInput type="text" formControlName="resultadoCurso" [(ngModel)]="resultadoCurso"
            placeholder="¿Qué obtendrás en nuestro curso?*" [ngClass]="{'is-invalid': isFieldInvalid('resultadoCurso')}"
            required>
          <mat-error *ngIf="isFieldInvalid('resultadoCurso')">
            Este campo es requerido.
          </mat-error>
        </mat-form-field> -->

        <!-- <div class="mb-3">
  <label for="resultadoCurso" class="form-label">¿Qué obtendrás en nuestro curso?</label>
  <input type="text" formControlName="resultadoCurso" (keydown.enter)="addResultado()" class="form-control placeholder-text" placeholder="¿Qué obtendrás en nuestro curso?*" [ngClass]="{'is-invalid': isFieldInvalid('resultadoCurso')}" required>
  <div *ngIf="isFieldInvalid('resultadoCurso')" class="text-danger">Este campo es requerido.</div>
</div> -->

        <!-- <mat-form-field required class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>¿Cuáles son tus deseos?</mat-label>
          <mat-chip-grid #chipGrid2>
            <mat-chip-row *ngFor="let resultado of resultados" (removed)="removeResultado(resultado)" [editable]="true"
              [aria-description]="'press enter to edit ' + resultado.name">
              {{ resultado.name }}
              <button matChipRemove [attr.aria-label]="'remove ' + resultado.name">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="¿Qué obtendrás en nuestro curso?" [matChipInputFor]="chipGrid2"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addResultado($event)" />
          </mat-chip-grid>
        </mat-form-field> -->

        <div class="mb-3">
          <label for="deseo" class="form-label">¿Quiénes nos respaldan?</label>
          <input type="file" formControlName="deseo" (change)="onFileSelected($event)"
            class="form-control placeholder-text" [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
          <div *ngIf="isFieldInvalid('deseo')" class="text-danger">
            Este campo es requerido.
          </div>
        </div>

        <!-- <div class="mb-3">
          <label for="problema" class="form-label">Problema</label>
          <input type= "text" formControlName="problema" [(ngModel)]="problema" class="form-control placeholder-text" placeholder="Problema*" [ngClass]="{'is-invalid': isFieldInvalid('problema')}" required>
          <div *ngIf="isFieldInvalid('problema')" class="text-danger">
            Este campo es requerido.
          </div>
        </div> -->
        <!-- <div class="mb-3">
          <label for="descripcion" class="form-label">Descripción</label>
          <textarea type= "text" formControlName="descripcion" [(ngModel)]="descripcion" class="form-control placeholder-text" placeholder="Descripción*"[ngClass]="{'is-invalid': isFieldInvalid('descripcion')}" required></textarea>
          <div *ngIf="isFieldInvalid('descripcion')" class="text-danger">
            Este campo es requerido.
          </div> 
        </div>-->
        <div class="mb-3">
          <label for="tipoRecurso" class="form-label">Tipo de recurso</label>
          <select formControlName="tipoRecurso" class="form-control custom-select-sm"
            (change)="onTipoRecursoChange($event)" required>
            <option value="" disabled selected>Seleccione el tipo de recurso</option>
            <option *ngFor="let tipo of tiposDeRecursos" [value]="tipo">{{ tipo }}</option>
          </select>
          <div *ngIf="isFieldInvalid('tipoRecurso')" class="text-danger">
            Este campo es requerido.
          </div>
        </div>

        <div class="mb-3" *ngIf="tipoRecursoSeleccionado"> <!-- Muestra solo si se ha seleccionado un tipo -->
          <label for="deseo" class="form-label">Subir archivo</label>
          <input type="file" (change)="onFile($event)" class="form-control placeholder-text"
            [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
          <div *ngIf="isFieldInvalid('deseo')" class="text-danger">
            Este campo es requerido.
          </div>
        </div>
        <!-- <div class="mb-3">
          <label for="tipoRecurso" class="form-label">Tipo de recurso</label>
          <select formControlName="tipoRecurso" class="form-control custom-select-sm" (change)="onTipoRecursoChange()"
            required>
            <option value="" disabled selected>Seleccione el tipo de recurso</option>
            <option *ngFor="let tipo of tiposDeRecursos" [value]="tipo">{{ tipo }}</option>
          </select>
          <div *ngIf="isFieldInvalid('tipoRecurso')" class="text-danger">
            Este campo es requerido.
          </div>
        </div>
        <div class="mb-3">
          <label for="recurso" class="form-label">Recurso</label>
          <input *ngIf="mostrarInputRecurso()" type="file" (change)="onRecursoFileSelected($event)"
            class="form-control file-input-small" required>
          <div *ngIf="isFieldInvalid('recurso')" class="text-danger">
            Este campo es requerido.
          </div>
        </div> -->
        <!-- <div class="mb-3">
          <label for="somos" class="form-label">¿Quiénes somos? </label>
          <textarea type="text" formControlName="somos" [(ngModel)]="somos" class="form-control placeholder-text"
            placeholder="¿Quiénes somos?*" [ngClass]="{'is-invalid': isFieldInvalid('somos')}" required></textarea>
          <div *ngIf="isFieldInvalid('somos')" class="text-danger">
            Este campo es requerido.
          </div>
        </div> -->
        <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>¿Quiénes somos?</mat-label>
          <textarea matInput required class="w-100" [(ngModel)]="somos" formControlName="somos"
            placeholder="¿Quiénes somos?*" [ngClass]="{'is-invalid': isFieldInvalid('somos')}">
          </textarea>
        </mat-form-field>
        <div *ngIf="isFieldInvalid('somos')" class="text-danger">
          Este campo es requerido.
        </div>

        <mat-form-field required class="w-100 mb-3" appearance="outline" floatLabel="always">
          <mat-label>FAQs</mat-label>
          <input matInput formControlName="FAQs" [(ngModel)]="FAQs" placeholder="FAQs*"
            [ngClass]="{'is-invalid': isFieldInvalid('FAQs')}" required />
          <mat-error *ngIf="isFieldInvalid('FAQs')">Este campo es requerido.</mat-error>
        </mat-form-field>

        <div class="d-flex justify-content-center">
          <button type="submit" class="btn btn-primary" [disabled]="infoForm.invalid">Guardar</button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-7" *ngIf="!crearPlantilla">
    <h5 class="mb-4 text-center text-dark">Esta es la vista previa de cómo se verá tu landing page.</h5> 

    <div class="bg-white">
      <div class="browser-window w-100">
        <div class="browser-header">
          <div class="buttons">
            <span class="button close"></span>
            <span class="button minimize"></span>
            <span class="button maximize"></span>
          </div>
          <div class="address-bar">
            <!-- <input type="text" disabled [value]="course.Slug" readonly> -->
          </div>
        </div>
        <div class="browser-content">
          <app-landing-curso 
          [gancho]="gancho"
          [micronicho]="micronicho"
          [propuesta]="propuesta"
          [deseo]="deseo"
          [GatilloM]="GatilloM"
          ></app-landing-curso>

          <ul>
            <li *ngFor="let resultado of resultados">
              {{ resultado.name }}
            </li>
          </ul>
          <div *ngIf="imagenUrl">
            <img [src]="imagenUrl" alt="Imagen seleccionada" class="img-fluid" />
          </div>
          <p *ngIf="archivoSeleccionado">Archivo seleccionado: {{ archivoSeleccionado.name }}</p>
          <p>{{somos}}</p>
          <p>{{FAQs}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container-fluid">
  <div class="row mt-custom justify-content-center">
    <div>
      <div class="card border-gray no-padding-bottom">
      <div class="card">
        <div class="card-body">
          Aqui iva el form
        </div>
      </div>
    </div>
  </div>
</div> -->