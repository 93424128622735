<div class="container">
    <div class="mt-20 mb-20" style="text-align: center;">
      <img src="../../../../assets/images/logo1.png" style="width: 30%;">
    </div>
    <section class="mt-20">
      <h1 style="color: #584294; font-weight: 800; font-size: 36px;">{{ gancho }}</h1>
      <h3>
        {{propuesta}}
      </h3>
  
    </section>
    <section class="main-section pad-70" style="margin-top: 1rem;">
  
      <div class="head-line">
        <h1 style="font-size: 36px; font-weight: 800;">{{ deseo }}</h1>
        <p class="mt-10 mb-10" style="color:#584294; font-weight: bolder; font-size: large;">De <strong style="color: red;"><del>$500</del></strong> USD a tan solo <strong style="color: red;">$399</strong> USD, por tiempo limitado.</p><!--{{ getValueByProperty('Landing-GM') }}-->
        <p><strong>Garantía de devolución de dinero sin riesgo durante 7 días.</strong></p>
  
        <!-- Temporizador de cuenta regresiva -->
        <div class="countdown-timer" style="font-size: 1.6rem; font-weight: bold; margin-bottom: 1rem;">
           
          <h3>Tiempo restante:</h3><h2 style="color: red;">00:00:00</h2>
        </div>
        <div>
          <button style="margin-bottom: 1rem;" class="edu-btnTransition" >Ver Curso</button>
        </div>
      </div>
      <!-- <img [src]="getValueByProperty('Landing-Imagen')" width="669" height="536" /> -->
    </section>
  
    <!-- <section class="benefit-section pad-70" style="margin-top: 2rem; margin-bottom: 2rem;">
      <div class="benefit-data">
        <h2>
          {{userData.name}}
        </h2>
      </div>
      <p style="line-height: 1.2;">
        {{userData.biography}}
      </p>
    </section> -->
    <!-- <section class="cta-section" style="margin-bottom: 2rem;"> -->
    <!-- <btn-primary btn-text="Fale com nossos especialistas"></btn-primary> -->
    <!-- <button class="edu-btnTransition" (click)="redirectRecurso()">Ver presentación</button>
    </section> -->
  
    <section class="main-section pad-70" style="margin-top: 2rem; margin-bottom: 2rem;">
      <div class="container">
        <div class="row">
          <h2 style="text-align: center;" class="mt-20 mb-30">¿Qué obtendrás en nuestro curso?</h2>
          <div class="col-md-6">
            <div class="head-line">
  
              <p>• Acceso vitalicio a nuestro curso donde aprenderás: </p>
              <ol *ngFor="let objective of dataCourse">
                <li><i class="fa fa-check"
                    style="margin-right: 1rem; color: #D96C70; margin-bottom: 1rem;"></i>{{objective}}</li>
              </ol>
              <!-- <p>✅Resultados inmediatos y medibles:</p>
              <ol>
                <li><i class="fa fa-check" style="margin-right: 1rem; color: #D96C70; margin-bottom: 1rem;"></i>Investigación de mercado profesional</li>
                <li><i class="fa fa-check" style="margin-right: 1rem; color: #D96C70; margin-bottom: 1rem;"></i>Investigación de mercado profesional</li>
                <li><i class="fa fa-check" style="margin-right: 1rem; color: #D96C70; margin-bottom: 1rem;"></i>Investigación de mercado profesional</li>
                <li><i class="fa fa-check" style="margin-right: 1rem; color: #D96C70;"></i>Investigación de mercado profesional</li>
              </ol> -->
            </div>
          </div>
          <div class="col-md-6" style="align-content: center;">
            <!-- <img [src]="getValueByProperty('Landing-ImgObtendras')" width="669" height="536" class="img-fluid" /> -->
          </div>
        </div>
      </div>
    </section>
    <div style="text-align: center; margin-bottom: 2rem;">
      <button style="margin-bottom: 1rem;" class="edu-btnTransition" >Ver Presentación</button>
    </div>
  </div>
  <div>
    <div class="container">
      <section style="text-align: center;">
        <img src="../../../../assets/img/banner/garantia.png">
        <h2>Garantía de devolución de dinero sin riesgo por 7 días.</h2>
        <p>Estamos tan seguros de que nuestro producto transformará tu negocio que ofrecemos una <strong>garantía de
            devolución de dinero de 7 días sin preguntas</strong>. Si no ves resultados, te devolvemos tu inversión.</p>
      </section>
    </div>
  </div>
  
  <div class="container">
  
    <section class="mt-60 mb-60">
      <div>
        <h1 class="mb-20" style=" text-align: center;">Imagina cuánto tiempo, dinero y libertad representan todas estas
          horas.</h1>
        <ol *ngFor="let deseo of deseos">
          <li>
          <li><i class="fa fa-check" style="margin-right: 1rem; color: #D96C70"></i>{{deseo}}</li>
        </ol>
        <p style="text-align: center; color:#584294; font-weight: bolder"></p><!--{{ getValueByProperty('Landing-GM') }}-->
        <p style="text-align: center;"><strong>Garantía de devolución de dinero sin riesgo durante 7 días</strong></p>
      </div>
    </section>
  </div>
  
  <section class="form-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 style="color: white; text-align: center;">¿Quienés nos respaldan?</h1>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let res of respaldo" class="col-md-3 col-sm-6 mb-4">
          <img [src]="res" class="img-fluid">
        </div>
      </div>
    </div>
  </section>
  
  <div class="container">
    <section class="main-section pad-70 mb-60" style="margin-top: 1rem;">
      <div class="head-line col-md-8">
        <h1>¿Quiénes somos?</h1>
        <!-- <h3>{{userData.name}}</h3> -->
        <!-- <p>{{ getValueByProperty('Landing-Somos') }}</p> -->
        <button style="margin-bottom: 1rem;" class="edu-btnTransition" >Contáctanos por
          Whatsapp</button>
      </div>
      <div class="image-container col-md-4 ">
        <!-- <img class="img-fluid" [src]="userData.profileUrl" /> -->
      </div>
    </section>
  </div>
  
  <section class="form-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 style="color: white; text-align: center;">¿Te quedaste con
            ganas de más?</h1>
          <h2 class="mb-20 mt-10" style="font-size: 1.5rem; text-align: center;">Y por si pensabas que era poco, te vamos
            a obsequiar los siguientes recursos.</h2>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let recurso of recursos" class="col-md-3 col-sm-6 mb-4">
          <img [src]="recurso" class="img-fluid" alt="recurso" style="max-height: 204px;">
        </div>
      </div>
      <div class="countdown-timer" style="font-size: 1.6rem; font-weight: bold; margin-bottom: 1rem; text-align: center;">   
        <h3 style="color: white; margin: 0;">Tiempo restante:</h3><h2 style="color: red;">{{countdown}}</h2>
      </div>
      <div style="text-align: center;">
        <button style="margin-bottom: 1rem;" class="edu-btnTransition">Aprovecha ahora</button>
      </div>
    </div>
  </section>
  
  
  
  <!-- 
  <section class="form-section" >
    <div class="text">
    
      <p style="color: white;">
        {{ getValueByProperty('Landing-Descripcion') }}
      </p>
    </div>
    <form id="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <label for="name">Name</label>
      <input id="name" formControlName="name" />
      
      <label for="email">E-mail</label>
      <input id="email" formControlName="email" />
  
      <label for="tel">Telefono</label>
      <input id="tel" formControlName="tel" />
      
      <button [disabled]="form.invalid"  type="submit" class="edu-btnTransition">Enviar</button>
      
    </form>
  </section> -->
  
  
  <div class="container mt-30 mb-30">
    <div class="row">
      <div class="col-md-6" style="align-content: center;">
        <div></div>
        <h1>¿Tienes Preguntas?</h1>
        <p style="font-size: 16px;">Si no encuentras la respuesta en esta pagina no dudes en contactarnos
        </p>
        <button class="edu-btnTransition mb-20">Whatsapp</button>
      </div>
  
  
      <!-- <div class="col-md-6">
        <div class="accordion" id="accordionExample">
          <div *ngFor="let prugunta of preguntas; let i = index" class="accordion-item">
  
            <h2 class="accordion-header" id="heading">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseAcor'+i" aria-expanded="true" aria-controls="collapseAcor">
                {{prugunta}}
              </button>
            </h2>
            <div *ngFor="let respuesta of respuestas let j = index" [attr.id]="'collapseAcor'+i" class="accordion-collapse collapse show" aria-labelledby="heading" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                {{respuesta}}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-md-6 course-curriculam-accodion mt-10 mb-10">
        <div class="accordion" id="accordionExample">
          <div *ngFor="let preguntaConRespuesta of preguntas; let i = index" class="accordion-item">
            <div class="accordion-body">
              <button (click)="bloqueSeleccionadoU(i, $event)" class="accordion-button" type="button"
                data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i">
                <span class="accordion-header">
                  <span class="accordion-tittle">
                    <span>{{preguntaConRespuesta.pregunta}}</span>
                  </span>
                </span>
              </button>
            </div>
  
            <div [attr.id]="'collapseOne'+i" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <div class="course-curriculum-content d-sm-flex justify-content-between align-items-center">
                  <div class="course-curriculum-info">
                    <h4>{{preguntaConRespuesta.respuestas}}</h4>
                  </div>
                  <div class="course-curriculum-meta">
                    <!-- <span>5:30</span>
                                <span class="time"> <i class="flaticon-lock"></i></span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    </div>
  </div>