import { Component,Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-curso',
  standalone: true,
  imports: [],
  templateUrl: './landing-curso.component.html',
  styleUrl: './landing-curso.component.scss'
})
export class LandingCursoComponent implements OnInit {
  @Input() gancho: string;
  @Input() propuesta: string;
  @Input() deseo: string;



  @Input() micronicho: string;
  @Input() GatilloM: string;
  

  public resultados:any [] = [];


  countdown: string = '';
  activoIndex: any;
  constructor() { }

  ngOnInit(): void {
    
  }

  bloqueSeleccionadoU(i: any, event: any) {

    if (this.activoIndex === i) {
      event.currentTarget.classList.remove('accordion-button-active');
      this.activoIndex = null; // Resetear el índice activo
    } else {
      // Si hay un índice activo previo, encuentra el botón correspondiente y remueve la clase
      if (this.activoIndex !== null) {
        const prevButton = document.querySelector(`[data-bs-target='#collapseOne${this.activoIndex}']`);
        if (prevButton) {
          prevButton.classList.remove('accordion-button-active');
        }
      }
      if (event.currentTarget.getAttribute('aria-expanded') === 'true') {
        event.currentTarget.classList.add('accordion-button-active');
        this.activoIndex = i
      } else {
        event.currentTarget.classList.remove('accordion-button-active');
      }
    }

    // this.seleccionado.emit({ posicion: i });

  }


}
